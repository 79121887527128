
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
    countdown: 20,
    countdownTimer: 0,
    target: "http://reshadecn.nightship.cn/"
  }),

  mounted() {
    this.countdown = 20;
    this.countdownTimer = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.countdownTimer);
        window.location.href = this.target;
      }
    }, 1000);
  },


});
