import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'


import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faLanguage, faFileZipper, faDownload, faFileCode, faGamepad, faPersonRunning, faCircleQuestion, faCommentDots, faWrench, faHashtag, faRightLong, faCircleInfo, faXmark, faX, faThumbsUp, faArrowLeft, faArrowRight, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
library.add(faUser, faLanguage, faFileZipper, faDownload, faFileCode, faGamepad, faPersonRunning, faCircleQuestion, faCommentDots, faWrench, faHashtag, faRightLong,faCircleInfo,faXmark, faThumbsUp, faArrowLeft, faArrowRight, faUpRightFromSquare);
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')