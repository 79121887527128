
  import Vue from 'vue'
  import axios from 'axios'
  import pageFooter from '../components/footer.vue'
  import {visit, download, advise} from '../axios/index'
import { faL } from '@fortawesome/free-solid-svg-icons'

  interface ICover{
    url: string,
    title: string,
    author?: string,
    link?: string,
    desc?: string
  }
  interface IVersion{
    version: string,
    update: string,
    CHNupdate: string,
    link: string
  }
  interface IQuestion{
    question: string,
    anwser: string,
  }
  interface ITranslate{
    title: string,
    content: string
  }
  interface IKeyword{
    keyword: string,
    name: string,
    desc: string
  }

  export default Vue.extend({
    name: 'HomePage', 
    components:{
      pageFooter
    },
    data(){
      return{
        coverImages:[ ] as ICover[],
        uiImages: [] as string[],
        mainLeft: 0,
        tabPage: 0,
        rule: [(v:string)=>{return !!v || '请填写此项'}],
        showToast: false,
        toastText: '',
        toastColor: 'success',
        adviseLoading: false,
        toastIcon: 'fa-solid fa-xmark',
        updateShader:[
          "Bessel_Bloom.fx",
          "BlueNoiseMotionBlur.fx",
          "CMAA_2.fx",
          "dh_crt.fx",
          "dh_rtgi.fx",
          "Drunk.fx",
          "Glamayre_Fast_Effects.fx",
          "Halftone.fx",
          "LensDistort.fx",
          "GoldenRatio.fx",
          "Heightfog.fx",
          "HQAA.fx",
          "RadialSlitScan.fx",
          "UIShadow.fx"
        ],
        updateShaderCNH:[
          "贝塞尔泛光",
          "蓝噪声动态模糊",
          "保守形态抗锯齿2",
          "DH 阴极射线管",
          "DH 光线追踪",
          "醉酒",
          "Glamayre 快速效果",
          "半色调",
          "镜头畸变",
          "黄金比例",
          "高度雾",
          "高质量抗锯齿",
          "放射性扫描",
          "UI阴影"
        ],
        updatePreset:[
          "ArKana",
          "Fairy",
          "Featured",
          "GShade",
          "Ipsusu",
          "Leeja Llen",
          "Maya Purple Cocoon",
          "Nacht",
          "Neneko ColorS [Universe]",
          "Nightingale"
        ],
        version:[
          {
            version: '4.1.1',
            update: '2023-02-05',
            CHNupdate: '2023-02-06',
            link: 'https://gshade.nightship.cn/Chinese_GShade_shaders_4.1.1.zip'
          },
          {
            version: '4.0.3',
            update: '2022-12-31',
            CHNupdate: '2023-01-01',
            link: 'https://gshade.nightship.cn/Chinese_GShade_shaders_4.0.3.zip'
          },
          {
            version: '3.5.2',
            update: '2022-09-25',
            CHNupdate: '2022-10-03',
            link: 'https://gshade.nightship.cn/Chinese_GShade_shaders_3.5.2.zip'
          },
          {
            version: '3.4.1',
            update: '2021-09-21',
            CHNupdate: '2022-01-15',
            link: 'https://gshade.nightship.cn/Chinese_GShade_shaders_3.4.1.zip'
          }
        ] as IVersion[],
        questions: [] as IQuestion[],
        keyword: [] as IKeyword[],
        translate: [] as ITranslate[],
        adviseContent:{
          content: '',
          contact: ''
        }
      }
    },
    methods:{
      getCover(){
        axios.get("https://gshade.nightship.cn/api/config.json")
        .then(res=>{
          if(res.status == 200){
            this.coverImages = res.data.cover;
            this.uiImages = res.data.ui;
            this.questions = res.data.question;
            this.translate = res.data.translate;
            this.keyword = res.data.keyword;
          }
        })
      },
      clickDownload(v:string){
        download(v);
      },
      toastSuccess(t:string){
        this.toastText = t;
        this.toastColor = 'success';
        this.toastIcon = "fa-solid fa-thumbs-up";
        this.showToast = true;
      },
      toastFail(t:string){
        this.toastText = t;
        this.toastColor = 'error';
        this.toastIcon = "fa-solid fa-xmark";
        this.showToast = true;
      },
      submitAdvise(){
        if(this.$refs.advise.validate()){
          advise(this.adviseContent).then(res=>{
            this.adviseLoading = false;
            if(res.status == 200){
              if(res.data.status == 'success'){
                this.toastSuccess('提交成功！感谢您的建议！');
                this.adviseContent = {content: '', contact: ''};
              }
              else{
                this.toastFail(res.data.errMsg);
              }
            }else{
              this.toastFail('请求错误，请重试：'+res.status);
            }
          }).catch(err=>{
            this.adviseLoading = false;
            this.toastFail('请求错误：'+err);
          })
        }
      }
    },
    mounted(){
      document.title = '首页 - GShade冲印室'
      this.getCover();
      this.mainLeft = (document.body.clientWidth - document.getElementById('m1').offsetWidth)/2;
      visit(document.referrer, '/');
    },
    computed: {
      coverHeight:function(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 300
          case 'sm': return 360
          case 'md': return 550
          case 'lg': return 550
          case 'xl': return 700
        }
        return 400;
      },
      uiHeight:function(){
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 300
          case 'sm': return 360
          case 'md': return 500
          case 'lg': return 500
          case 'xl': return 500
        }
        return 400;
      },

    },
  })
