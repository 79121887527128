import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3c3658',
        secondary: '#6C658A',
        info: '#5A2E2A'
      },
    },
  },
});
