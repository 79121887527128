import axios from "axios";

export function visit(source:string, page:string){
  axios.get("https://gshade.nightship.cn/api/visit.php?source="+escape(source)+"&page="+escape(page));
}

export function download(version:string){
  axios.get("https://gshade.nightship.cn/api/download.php?version="+escape(version));
}

export function advise(v:object){
  return axios.post("https://gshade.nightship.cn/api/advise.php",v);
}

export function getVisitDetail(page:number, length:number, pw:string){
  return axios.get("https://gshade.nightship.cn/api/getVisitDetail.php?page="+page+"&length="+length+"&pw="+pw);
}

export function getAdvise(page:number, length:number, pw:string){
  return axios.get("https://gshade.nightship.cn/api/getAdvise.php?page="+page+"&length="+length+"&pw="+pw);
}